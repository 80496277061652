import "chonky/style/main.css";
import "./chonky.css"; // style overrides

import FF from "../../../components/forms/FormField.module.css";
import RA from "../../../components/forms/Radio.module.css";
import R from "../../../components/forms/Radiogroup.module.css";
import TF from "../../../components/forms/TextField.module.css";
import P from "../../../components/Progress.module.css";
import C from "./S3FileBrowser.module.css";

import { Sha256 } from "@aws-crypto/sha256-browser";
import {
    AbortMultipartUploadCommand,
    CompleteMultipartUploadCommand,
    CopyObjectCommand,
    CreateMultipartUploadCommand,
    DeleteObjectCommand,
    GetObjectAclCommand,
    type GetObjectAclCommandOutput,
    GetObjectCommand,
    ListObjectsV2Command,
    type ListObjectsV2CommandOutput,
    type ObjectCannedACL,
    PutObjectAclCommand,
    PutObjectCommand,
    S3Client,
    type S3ClientConfig,
    UploadPartCommand,
} from "@aws-sdk/client-s3";
import type { CommonPrefix, Grant, _Object as S3Object } from "@aws-sdk/client-s3/dist-types/models";
import { getSignedUrl, S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { formatUrl } from "@aws-sdk/util-format-url";
import { HttpRequest } from "@smithy/protocol-http";
import { parseUrl } from "@smithy/url-parser";
import { singleElement } from "@warrenio/utils/collections/singleElement";
import { isObjectWithKey } from "@warrenio/utils/isObject";
import { discardAsync, discardPromise } from "@warrenio/utils/promise/discardPromise";
import {
    ChonkyActions,
    ChonkyIconName,
    type FileAction,
    type FileActionData,
    type FileActionHandler,
    type FileArray,
    FileBrowser,
    type FileData,
    FileHelper,
    FileList,
    FileSearch,
    FileToolbar,
} from "chonky";
import { type ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Input, Radio, RadioGroup } from "react-aria-components";
import { useDropzone } from "react-dropzone";
import { WButton } from "../../../components/button/WButton.tsx";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import { WCheckbox } from "../../../components/forms/WCheckbox.tsx";
import { WTextField } from "../../../components/forms/WTextField.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { LongDate } from "../../../components/l10n/DateFormat.tsx";
import { Loading } from "../../../components/loading/Loading.tsx";
import { ModalRow } from "../../../components/modal/ModalRow.tsx";
import { showModal } from "../../../components/modal/registerModal.tsx";
import { WModalContent } from "../../../components/modal/WModal.tsx";
import { Spinner } from "../../../components/Spinner.tsx";
import { WTooltip } from "../../../components/WTooltip.tsx";
import { isMockApiEnabled } from "../../../mock-api/msw/mswInit.ts";
import { cn } from "../../../utils/classNames.ts";
import { unsetPropertyCheckProxy } from "../../../utils/debug/unsetPropertyCheckProxy.ts";
import { useApiClient } from "../../api/apiClient.store.ts";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { raiseToast, type Toast } from "../../notifications/toast.tsx";
import { bucketCorsRequest } from "./api.ts";
import { formatBytes } from "./formatBytes.ts";

const UPLOAD_CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunks

const MAX_KEYS = 1000; // maximum records to fetch in one chunk
const DELETE_MAX_KEYS = 2000; // maximum related objects that can be deleted when deleting a "folder"
const RENAME_MAX_KEYS = 2000; // maximum related objects that can be copied over during rename action on a "folder"

// maximum number of chunks to be loaded before pausing and asking user to continue
const CHUNK_COUNTER_PAUSE = 10; // 10 x 'MaxKeys' (1000) = 10 000 records

// maximum number of chunks to be loaded to prevent browser hanging or crashing
const CHUNK_COUNTER_LIMIT = 100; // 100 x 'MaxKeys' (1000) = 100 000 records

interface UploadProgressFile {
    orderNr: number;
    uploadId: string;
    key: FileData["id"];
    modDate: Exclude<FileData["modDate"], undefined>;
    size: number;
    uploadedSize: number;
    // 'finalized' means that upload is complete and also added to 'files' state variable
    status: "in_progress" | "complete" | "finalized" | "failed" | "cancelled";
    errorMessage?: string;
    cancelInProgress: boolean;
}

interface UploadStats {
    totalSize: number;
    uploadedSize: number;
    totalFiles: number;
    uploadedFiles: number;
    percentage: number;
}

type BrowserLoadStateType = "idle" | "loading" | "paused" | "finished" | "halted";

interface ShareLinkResult {
    path_style_url: string;
    vhost_style_url?: string;
    expires: number; // timestamp
}

interface MyFileAction extends FileAction {
    mobileIconOnly?: boolean;
}

//#region Toolbar actions

const MyOpenFilesAction = {
    id: "open_files",
    requiresSelection: true,
    hotkeys: ["enter"],
} satisfies MyFileAction;

const MyToggleShowFoldersFirst = {
    id: "toggle_show_folders_first",
    option: {
        id: "show_folders_first",
        defaultValue: true,
    },
} satisfies MyFileAction;

const MyDownloadFiles = {
    id: "download_files",
    requiresSelection: true,
    mobileIconOnly: true,
    toolbarButton: {
        name: "Download",
        tooltip: "Download",
        icon: ChonkyIconName.download,
    },
} satisfies MyFileAction;

const MyDeleteFiles = {
    id: "delete_files",
    requiresSelection: true,
    hotkeys: ["delete"],
    mobileIconOnly: true,
    toolbarButton: {
        name: "Delete",
        tooltip: "Delete",
        icon: ChonkyIconName.trash,
    },
} satisfies MyFileAction;

const MyUploadFiles = {
    id: "upload_files",
    mobileIconOnly: true,
    toolbarButton: {
        name: "Upload",
        tooltip: "Upload",
        icon: ChonkyIconName.upload,
    },
} satisfies MyFileAction;

const MyCreateFolder = {
    id: "create_folder",
    mobileIconOnly: true,
    toolbarButton: {
        name: "New Folder",
        tooltip: "New Folder",
        icon: ChonkyIconName.folderCreate,
    },
} satisfies MyFileAction;

const MyRenameFiles = {
    id: "rename_files",
    requiresSelection: true,
    toolbarButton: {
        name: "Rename",
        tooltip: "Rename",
        icon: ChonkyIconName.fallbackIcon,
    },
} satisfies MyFileAction;

const MyShare = {
    id: "share",
    requiresSelection: true,
    toolbarButton: {
        name: "Temporary Share",
        tooltip: "Temporary Share",
        icon: ChonkyIconName.fallbackIcon,
        group: "Access",
        dropdown: true,
    },
} satisfies MyFileAction;

const MyPermissions = {
    id: "permissions",
    requiresSelection: true,
    toolbarButton: {
        name: "Permissions",
        tooltip: "Set Permissions",
        icon: ChonkyIconName.fallbackIcon,
        group: "Access",
        dropdown: true,
    },
} satisfies MyFileAction;

const MyCopyPublicUrl = {
    id: "copy_public_url",
    requiresSelection: true,
    toolbarButton: {
        name: "Copy Public URL",
        tooltip: "Copy Public URL",
        icon: ChonkyIconName.fallbackIcon,
        group: "Access",
        dropdown: true,
    },
} satisfies MyFileAction;

//#endregion

const LOADING_FILES: FileArray = [null];

export interface S3ClientProps {
    accessKeyId: string;
    secretAccessKey: string;
    endpoint: string;
    forcePathStyle: boolean;
    //TODO: region on uus props, ja see ei ole meil kuskil olemas, aga V3 nõuab
    region: string;
}

export interface S3FileBrowserProps {
    clientOptions: S3ClientProps;
    bucketName: string;
    mobileView: boolean;
    mockLoadState?: BrowserLoadStateType;
}

export function S3FileBrowser({
    clientOptions: { accessKeyId, secretAccessKey, endpoint, forcePathStyle, region },
    bucketName,
    mobileView,
    // Mocks
    mockLoadState,
}: S3FileBrowserProps): ReactNode {
    const [currentFolderId, setCurrentFolderId] = useState<string>("");
    const [isInitializing, setIsInitializing] = useState<boolean>(true);
    const [folderChain, setFolderChain] = useState<FileData[]>([]);
    const [files, setFiles] = useState<FileData[]>([]);
    const [uploadProgressFiles, setUploadProgressFiles] = useState<UploadProgressFile[]>([]);

    const [browserLoadState, setBrowserLoadState] = useState<BrowserLoadStateType>("idle");
    const [chunkCounter, setChunkCounter] = useState<number>(0);
    const [continueMarker, setContinueMarker] = useState<string | undefined>(undefined);
    const [loadTrigger, setLoadTrigger] = useState<number>(0);

    const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
    const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);

    const apiClient = useApiClient();

    useEffect(() => {
        // setting up CORS for browsing buckets
        discardAsync(async () => {
            await bucketCorsRequest(apiClient, {
                name: bucketName,
                allowed_origin: document.location.origin,
            });

            setCurrentFolderId("");
            setFolderChain([{ id: " ", name: bucketName, isDir: true }]);
            setIsInitializing(false);
        });
    }, []);

    const s3 = useMemo(() => {
        const params: S3ClientConfig = {
            apiVersion: "latest",
            credentials: {
                accessKeyId,
                secretAccessKey,
            },
            region,
            endpoint,
            forcePathStyle,
        };
        return isMockApiEnabled() ? MockS3.make(params) : new S3Client(params);
    }, [accessKeyId, secretAccessKey, endpoint, forcePathStyle, region]);

    const isMock = s3 instanceof MockS3;

    //#region Mock effects
    useEffect(() => {
        if (isMockApiEnabled()) {
            setUploadProgressFiles(mockUploadProgress);
        }
    }, []);

    useEffect(() => {
        if (mockLoadState) {
            setBrowserLoadState(mockLoadState);
        }
    }, [mockLoadState]);
    //#endregion

    useEffect(() => {
        // NB: Must not call S3 before CORS is set up
        if (isInitializing) {
            return;
        }

        const prefix = currentFolderId.trim(); // XXX: Is the trim necessary?
        setBrowserLoadState("loading");
        discardAsync(async () => {
            try {
                const _files = await listS3BucketFiles(s3, bucketName, prefix);
                if (chunkCounter >= CHUNK_COUNTER_PAUSE) {
                    // append to files
                    setFiles((prevFiles) =>
                        _files.filter((_f) => !prevFiles.some((_prevF) => _prevF.id === _f.id) /* ensure unique ids*/),
                    );
                } else {
                    // replace file list
                    setFiles(_files);
                }
            } catch (error) {
                if (isObjectWithKey(error, "code")) {
                    if (error.code === "NoSuchBucket") {
                        Toast_error(`Bucket '${bucketName}' does not exist. Please check the bucket name.`);
                    } else {
                        Toast_error(`An error occurred: ${getErrorMessage(error)}`);
                    }
                } else {
                    console.error("Unknown S3 error occurred", error);
                }
                setFiles([]); // remove loader
            }
        });
    }, [isInitializing, currentFolderId, loadTrigger, mockFiles]);

    const handleFileAction = useCallback<FileActionHandler>(
        (action: FileAction, actionData: FileActionData) => {
            console.log("action[%s]: %o", action.id, actionData);
            if (browserLoadState === "loading") {
                return;
            }

            function startFileDownload(file: FileData) {
                if (!file.isDir) {
                    const command = new GetObjectCommand({
                        Bucket: bucketName,
                        Key: file.id,
                        ResponseContentDisposition: `attachment; filename="${file.name}"`,
                    });
                    isMock
                        ? s3.getSignedUrl(file, 3600)
                        : getSignedUrl(s3, command, { expiresIn: 60 }).then((url) => {
                              window.open(url, "_blank");
                              Analytics_event({ category: "S3 File Browser", action: "File Download Initiated" });
                          });
                } else {
                    Toast_error("This action is available only for files");
                }
            }

            const { id } = action;
            if (id === MyCopyPublicUrl.id) {
                const file = singleElement(actionData.files);
                if (file) {
                    if (!file.isDir) {
                        navigator.clipboard.writeText(getObjectsPublicUrl(file.id)).then(() => {
                            Toast_info("Object's public URL copied to clipboard");
                        });
                    } else {
                        Toast_error("This action is available only for files");
                    }
                } else {
                    Toast_error("This action is available only for one file");
                }
            } else if (id === MyShare.id) {
                const file = actionData.files![0];
                if (!file.isDir) {
                    showModal(
                        <ShareModal
                            file={file}
                            callback={async (linkAge) => {
                                Analytics_event({
                                    category: "S3 File Browser",
                                    action: "Share Temp File Link Initiated",
                                });
                                return await generateShareLink(file, linkAge);
                            }}
                        />,
                    );
                } else {
                    Toast_error("This action is available only for files");
                }
            } else if (id === MyPermissions.id) {
                const files = actionData.files!;
                const containsDirs = files.find((f: FileData) => f.isDir);
                if (!containsDirs) {
                    const singleFile = singleElement(files);

                    showModal(
                        <PermissionsModal
                            s3={s3}
                            bucketName={bucketName}
                            publicUrl={singleFile ? getObjectsPublicUrl(singleFile.id) : undefined}
                            files={files}
                            callback={async (permission) => {
                                Analytics_event({ category: "S3 File Browser", action: "Set Permissions Initiated" });
                                return await setFilePermission(files, permission);
                            }}
                        />,
                    );
                } else {
                    Toast_error("This action is available only for files");
                }
            } else if (id === MyRenameFiles.id) {
                const selectedFiles = actionData.files!;
                if (selectedFiles.length === 1) {
                    const file = selectedFiles[0];

                    showModal(
                        <RenameFileOrFolderModal
                            file={file}
                            callback={async (newName) => {
                                Analytics_event({ category: "S3 File Browser", action: "File Rename Initiated" });
                                return await renameFileOrFolder(file, newName);
                            }}
                        />,
                    );
                } else {
                    Toast_error("This action is available only for one file or folder");
                }
            } else if (id === MyDeleteFiles.id) {
                const files = actionData.files!;

                showModal(
                    <DeleteFileOrFolderModal
                        files={files}
                        callback={async () => {
                            Analytics_event({ category: "S3 File Browser", action: "File Delete Initiated" });
                            return await deleteFileOrFolder(files);
                        }}
                    />,
                );
            } else if (id === ChonkyActions.UploadFiles.id) {
                Analytics_event({ category: "S3 File Browser", action: "File Upload Initiated" });
                open();
            } else if (id === MyDownloadFiles.id) {
                const selectedFiles = actionData.files!;
                if (selectedFiles.length === 1) {
                    startFileDownload(selectedFiles[0]);
                } else {
                    Toast_error("This action is available only for one file");
                }
            } else if (id === MyOpenFilesAction.id) {
                //let targetFile: Nullable<FileData> = null;
                let targetFile: FileData | null = null;
                if (actionData.target) {
                    targetFile = actionData.target;
                } else if (actionData.files && actionData.files.length === 1) {
                    targetFile = actionData.files[0];
                }

                if (targetFile && FileHelper.isDirectory(targetFile)) {
                    setFiles([]);
                    setCurrentFolderId(targetFile.id);

                    setChunkCounter(0);
                    setBrowserLoadState("idle");
                    setContinueMarker(undefined);

                    const existingLinkInChainIndex = folderChain.findIndex((dir) => dir && dir.id === targetFile.id);
                    if (existingLinkInChainIndex !== -1) {
                        // navigating upward in directory chain
                        setFolderChain(folderChain.slice(0, existingLinkInChainIndex + 1));
                    } else {
                        // navigating into sub-directory, add folder to the end of chain
                        setFolderChain([...folderChain, targetFile]);
                    }
                    return;
                } else {
                    startFileDownload(targetFile!);
                    return;
                }
            } else if (id === MyCreateFolder.id) {
                showModal(
                    <CreateFolderModal
                        callback={async (name) => {
                            Analytics_event({ category: "S3 File Browser", action: "Folder Create Initiated" });
                            return await createFolder(name);
                        }}
                    />,
                );
            }
        },
        [s3, currentFolderId, folderChain, browserLoadState, isFullscreen],
    );

    async function listS3BucketFiles(s3: S3Client, bucket: string, prefix: string) {
        const files: FileData[] = [];
        const s3Items: S3FilesAndDirs = await listS3Keys(s3, bucket, prefix);
        const prefixReplaceRegex = new RegExp(`^${prefix}`);

        for (const obj of s3Items.files) {
            if (obj.Key !== prefix) {
                files.push({
                    id: obj.Key!,
                    name: obj.Key!.split("/").pop()!, // remove directory path from file name
                    modDate: obj.LastModified,
                    size: obj.Size,
                });
            }
        }
        for (const obj of s3Items.dirs) {
            files.push({
                id: obj.Prefix!,
                name: obj.Prefix!.replace(prefixReplaceRegex, "").replace(/\/+$/, ""), // remove also trailing slash as file browser component adds this automatically
                isDir: true,
            });
        }
        return files;
    }

    interface S3FilesAndDirs {
        files: S3Object[];
        dirs: CommonPrefix[];
    }

    async function listS3Keys(s3client: S3Client, bucket: string, prefix: string): Promise<S3FilesAndDirs> {
        let continuationToken: string | undefined = continueMarker;
        const itemList: S3FilesAndDirs = {
            files: [],
            dirs: [],
        };
        let counter = chunkCounter;
        do {
            const command = new ListObjectsV2Command({
                Prefix: prefix,
                Bucket: bucket,
                ContinuationToken: continuationToken,
                MaxKeys: MAX_KEYS,
                Delimiter: "/",
            });
            const res = await s3client.send(command);

            counter += 1;

            continuationToken = res.NextContinuationToken ?? undefined;
            itemList.files = [...itemList.files, ...(res.Contents ?? [])];
            itemList.dirs = [...itemList.dirs, ...(res.CommonPrefixes ?? [])];
        } while (continuationToken !== undefined && counter % CHUNK_COUNTER_PAUSE && counter < CHUNK_COUNTER_LIMIT);

        if (counter >= CHUNK_COUNTER_LIMIT) {
            setChunkCounter(0);
            setBrowserLoadState("halted");
            setContinueMarker(undefined);
        } else if (continuationToken === undefined) {
            setChunkCounter(0);
            setBrowserLoadState("finished");
            setContinueMarker(undefined);
        } else {
            setChunkCounter(counter);
            setBrowserLoadState("paused");
            setContinueMarker(continuationToken);
        }

        return itemList;
    }

    const fileActions = useMemo(
        () =>
            [
                MyCreateFolder,
                MyUploadFiles,

                MyDownloadFiles,
                MyDeleteFiles,
                MyRenameFiles,

                ChonkyActions.ChangeSelection,

                ChonkyActions.OpenParentFolder,

                MyShare,
                MyPermissions,
                MyCopyPublicUrl,

                ChonkyActions.EnableListView,
                ChonkyActions.EnableGridView,

                MyOpenFilesAction,

                ChonkyActions.SortFilesByName,
                ChonkyActions.SortFilesBySize,
                ChonkyActions.SortFilesByDate,

                MyToggleShowFoldersFirst,
            ].map(
                (action: MyFileAction): MyFileAction =>
                    mobileView && action.mobileIconOnly && action.toolbarButton
                        ? {
                              ...action,
                              toolbarButton: {
                                  ...action.toolbarButton,
                                  name: "",
                              },
                          }
                        : action,
            ),
        [mobileView],
    );

    function loadMore() {
        setLoadTrigger(loadTrigger + 1);
    }

    useEffect(() => {
        function escFunction(event: KeyboardEvent) {
            if (event.key === "Escape") {
                updateFullScreenState(false);
            }
        }

        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    useEffect(() => {
        const completedUploads = [...uploadProgressFiles.filter((uf: UploadProgressFile) => uf.status === "complete")];
        if (completedUploads.length) {
            const finalizedUploads = completedUploads.map(
                (cu: UploadProgressFile): UploadProgressFile => ({
                    ...cu,
                    status: "finalized",
                }),
            );
            setUploadProgressFiles([
                ...uploadProgressFiles.filter((uf: UploadProgressFile) => uf.status !== "complete"),
                ...finalizedUploads,
            ]);

            for (const completeUpload of completedUploads) {
                setFiles((prevFiles) => {
                    if (prevFiles.find((_f) => _f.id === completeUpload.key)) {
                        // ensure that id's are always unique in files' list
                        return [...prevFiles];
                    } else {
                        // update files' list only if user is currently located in the same directory that the file was uploaded to
                        const fileName = completeUpload.key.split("/").pop()!;
                        if (completeUpload.key.replace(fileName, "") === currentFolderId) {
                            return [
                                ...prevFiles,
                                {
                                    id: completeUpload.key,
                                    name: fileName,
                                    modDate: completeUpload.modDate,
                                    size: completeUpload.size,
                                },
                            ];
                        }
                        return prevFiles;
                    }
                });
            }
        }

        const finalizedUploads = [...uploadProgressFiles.filter((uf: UploadProgressFile) => uf.status === "finalized")];
        if (uploadProgressFiles.length > 0 && finalizedUploads.length === uploadProgressFiles.length) {
            // empty progress array if all uploads are finalized
            setUploadProgressFiles([]);
            setIsUploadOpen(false);
        }
    }, [uploadProgressFiles]);

    const uploadStats = useMemo<UploadStats>(() => {
        const totalSize = uploadProgressFiles.reduce((a, b) => +a + +b.size, 0);
        const uploadedSize = uploadProgressFiles.reduce((a, b) => +a + +b.uploadedSize, 0);
        return {
            totalSize: totalSize,
            uploadedSize: uploadedSize,
            totalFiles: uploadProgressFiles.length,
            uploadedFiles: uploadProgressFiles.filter(
                (p: UploadProgressFile) => p.status === "complete" || p.status === "finalized",
            ).length,
            percentage: (uploadedSize / totalSize) * 100,
        };
    }, [uploadProgressFiles]);

    async function uploadFileWithMultipart(s3client: S3Client, file: File) {
        const command = new CreateMultipartUploadCommand({
            Bucket: bucketName,
            Key: `${currentFolderId}${file.name}`,
            ContentType: file.type,
        });
        let data;
        try {
            data = await s3client.send(command);
        } catch (err) {
            console.error("Error creating multipart upload", err);
            return;
        }

        const uploadId = data.UploadId!;
        const totalChunks = Math.ceil(file.size / UPLOAD_CHUNK_SIZE);

        setUploadProgressFiles((prevState: UploadProgressFile[]): UploadProgressFile[] => [
            ...prevState,
            {
                orderNr: prevState.length + 1,
                uploadId,
                key: command.input.Key!, // TODO: Fix this type
                modDate: new Date(),
                size: file.size,
                uploadedSize: 0,
                status: "in_progress",
                cancelInProgress: false,
            },
        ]);

        async function uploadParts() {
            const parts = [];

            for (let partNumber = 1; partNumber <= totalChunks; partNumber++) {
                const start = (partNumber - 1) * UPLOAD_CHUNK_SIZE;
                const end = Math.min(partNumber * UPLOAD_CHUNK_SIZE, file.size);

                const chunk = file.slice(start, end);

                const uploadPartCommand = new UploadPartCommand({
                    Bucket: command.input.Bucket,
                    Key: command.input.Key,
                    UploadId: uploadId,
                    PartNumber: partNumber,
                    Body: chunk,
                });

                try {
                    const partData = await s3client.send(uploadPartCommand);
                    parts.push({ ETag: partData.ETag, PartNumber: partNumber });

                    setUploadProgressFiles((prevState: UploadProgressFile[]) => {
                        const activeFile = prevState.find((uf) => uf.uploadId === uploadId)!;
                        activeFile.uploadedSize = Math.min(partNumber * UPLOAD_CHUNK_SIZE, activeFile.size);
                        return [...prevState.filter((uf) => uf.uploadId !== uploadId), activeFile];
                    });
                } catch (error) {
                    setUploadProgressFiles((prevState: UploadProgressFile[]) => {
                        // if entry with status 'in_progress' exists in 'uploadProgressFiles' variable, then it's unexpected error
                        // attach error message to the progress file
                        const activeFile = prevState.find(
                            (uf) => uf.uploadId === uploadId && uf.status === "in_progress",
                        );
                        if (activeFile) {
                            activeFile.uploadedSize = 0;
                            activeFile.status = "failed";
                            activeFile.errorMessage = `Uploading failed: ${getErrorMessage(error)}`;
                            return [...prevState.filter((uf) => uf.uploadId !== uploadId), activeFile];
                        }
                        // otherwise we cancelled upload, just remove the entry from 'uploadProgressFiles'
                        return [...prevState];
                    });
                    return;
                }
            }

            return parts;
        }

        try {
            const parts = await uploadParts();
            if (parts === undefined) {
                throw new Error("Can't complete upload, something happened during uploading parts");
            }
            const completeCommand = new CompleteMultipartUploadCommand({
                Bucket: command.input.Bucket,
                Key: command.input.Key,
                UploadId: uploadId,
                MultipartUpload: {
                    Parts: parts,
                },
            });

            await s3client.send(completeCommand);

            setUploadProgressFiles((prevState: UploadProgressFile[]) => {
                const activeFile = prevState.find((uf) => uf.uploadId === uploadId)!;
                activeFile.uploadedSize = activeFile.size;
                activeFile.status = "complete";
                return [...prevState.filter((uf) => uf.uploadId !== uploadId), activeFile];
            });
        } catch (error) {
            setUploadProgressFiles((prevState: UploadProgressFile[]) => {
                // if entry with status 'in_progress' exists in 'uploadProgressFiles' variable, then it's unexpected error
                // attach error message to the progress file
                const activeFile = prevState.find((uf) => uf.uploadId === uploadId && uf.status === "in_progress");
                if (activeFile) {
                    activeFile.uploadedSize = 0;
                    activeFile.status = "failed";
                    activeFile.errorMessage = `Uploading failed: ${getErrorMessage(error)}`;
                    return [...prevState.filter((uf) => uf.uploadId !== uploadId), activeFile];
                }
                // otherwise we cancelled upload, just remove the entry from 'uploadProgressFiles'
                return [...prevState];
            });
        }
    }

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            Analytics_event({ category: "S3 File Browser", action: "File Upload Initiated" });

            for (const file of acceptedFiles) {
                uploadFileWithMultipart(s3, file);
            }
        },
        [currentFolderId],
    );

    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        noDragEventsBubbling: true,
    });

    // clear or cancel upload, depending on context
    async function clearUpload(uploadFile: UploadProgressFile): Promise<boolean> {
        if (uploadFile.errorMessage && uploadFile.status === "failed") {
            // we are removing 'uploadProgressFiles' entry that has failed previously
            setUploadProgressFiles((prevState: UploadProgressFile[]) => [
                ...prevState.filter((uf) => uf.uploadId !== uploadFile.uploadId),
            ]);
            return true;
        } else {
            setUploadProgressFiles((prevState: UploadProgressFile[]) => {
                const activeFile = prevState.find((uf) => uf.uploadId === uploadFile.uploadId);
                if (activeFile) {
                    activeFile.cancelInProgress = true;
                    activeFile.status = "cancelled";
                    return [...prevState.filter((uf) => uf.uploadId !== uploadFile.uploadId), activeFile];
                }
                return [...prevState];
            });
            // we are aborting upload in progress
            try {
                await s3.send(
                    new AbortMultipartUploadCommand({
                        Bucket: bucketName,
                        Key: uploadFile.key,
                        UploadId: uploadFile.uploadId,
                    }),
                );
                setUploadProgressFiles((prevState: UploadProgressFile[]) => [
                    ...prevState.filter((uf) => uf.uploadId !== uploadFile.uploadId),
                ]);
                return true;
            } catch (err) {
                return false;
            }
        }
    }

    async function clearAllUploads() {
        // set 'status' and 'cancelInProgress' props up front,
        // so that UI doesn't allow to cancel file a second time with 'Cancel' button click
        setUploadProgressFiles((prevState: UploadProgressFile[]) => {
            for (const uf of prevState) {
                if (uf.status !== "complete" && uf.status !== "finalized") {
                    uf.cancelInProgress = true;
                    uf.status = uf.status !== "failed" ? "cancelled" : uf.status;
                }
            }
            return [...prevState];
        });

        const notCompletedUploadFiles = uploadProgressFiles.filter(
            (up: UploadProgressFile) => up.status !== "complete" && up.status !== "finalized",
        );

        for (const file of notCompletedUploadFiles) {
            await clearUpload(file);
        }
        setIsUploadOpen(false);
    }

    async function deleteFileOrFolder(files: FileData[]): Promise<boolean> {
        async function deleteObjectsInDirectory(prefix: string): Promise<boolean> {
            const listCommand = new ListObjectsV2Command({
                Bucket: bucketName,
                Prefix: prefix,
                MaxKeys: DELETE_MAX_KEYS,
            });
            try {
                const listData = await s3.send(listCommand);
                if (listData.Contents) {
                    for (const object of listData.Contents) {
                        const deleteCommand = new DeleteObjectCommand({
                            Bucket: bucketName,
                            Key: object.Key!,
                        });
                        await s3.send(deleteCommand);
                    }
                }

                if (listData.CommonPrefixes) {
                    for (const commonPrefix of listData.CommonPrefixes) {
                        await deleteObjectsInDirectory(commonPrefix.Prefix!);
                    }
                }

                return true;
            } catch (error) {
                console.error("Error deleting objects in directory:", error);
                return false;
            }
        }

        try {
            await Promise.all(
                files.map(async (file: FileData) => {
                    if (file.isDir) {
                        try {
                            const result = await deleteObjectsInDirectory(file.id);
                            if (result) {
                                setFiles((prevState) => prevState.filter((f) => !f.id.startsWith(file.id)));
                            }
                            return result;
                        } catch (err) {
                            console.error("Error deleting file", err);
                            return false;
                        }
                    } else {
                        try {
                            await s3.send(new DeleteObjectCommand({ Bucket: bucketName, Key: file.id }));
                            setFiles((prevState) => prevState.filter((f) => f.id !== file.id));
                            return true;
                        } catch (err) {
                            console.error("Error deleting file", err);
                            return false;
                        }
                    }
                }),
            );
            return true;
        } catch (error) {
            Toast_error("Some files or folders were not deleted because error occurred");
            console.error(error);
            return false;
        }
    }

    async function generateShareLink(file: FileData, age: number): Promise<ShareLinkResult | null> {
        try {
            const command = new GetObjectCommand({
                Bucket: bucketName,
                Key: file.id,
            });
            const signedUrl = isMock ? s3.getSignedUrl(file, age) : await getSignedUrl(s3, command, { expiresIn: age });
            const url = new URL(signedUrl);

            const expires = Number(url.searchParams.get("X-Amz-Expires"));

            const protocol = url.protocol;
            const subdomain = url.hostname.split(".")[0];
            const domain = url.hostname.split(".").slice(1).join(".");
            const pathAndQuery = url.pathname + url.search;

            const presigner = new S3RequestPresigner({
                credentials: s3.config.credentials,
                region: s3.config.region,
                sha256: Sha256,
            });
            const signedUrlObject = await presigner.presign(
                new HttpRequest({ ...parseUrl(`${protocol}//${domain}/${subdomain}${pathAndQuery}`) }),
            );
            const pathStyleUrl =
                (forcePathStyle || bucketName.includes(".")) && !isMock ? url : formatUrl(signedUrlObject);

            const result: ShareLinkResult = {
                path_style_url: pathStyleUrl.toString(),
                expires,
            };

            if ((!forcePathStyle && !bucketName.includes(".")) || isMock) {
                result.vhost_style_url = url.toString();
            }
            return result;
        } catch (error) {
            console.error("Generating share url failed:", error);
            return null;
        }
    }

    async function setFilePermission(files: FileData[], permission: ObjectCannedACL): Promise<boolean> {
        try {
            // Fix this type
            await Promise.all(
                files.map(async (f: FileData) => {
                    const command = new PutObjectAclCommand({
                        Bucket: bucketName,
                        Key: f.id,
                        ACL: permission,
                    });

                    return await s3.send(command);
                }),
            );
            return true;
        } catch (error) {
            Toast_error("Error setting permission");
            console.error(error);
            return false;
        }
    }

    async function renameFileOrFolder(file: FileData, newName: string): Promise<boolean> {
        const keyChunks = file.id.split("/");

        async function renameObjectsInDirectory(prefix: string): Promise<boolean> {
            const listCommand = new ListObjectsV2Command({
                Bucket: bucketName,
                Prefix: prefix,
                MaxKeys: RENAME_MAX_KEYS,
            });
            try {
                const listData = await s3.send(listCommand);
                if (listData.Contents) {
                    for (const object of listData.Contents) {
                        const sourceKey = object.Key!;
                        const keyChunks = prefix.replace(/\/+$/, "").split("/");
                        keyChunks.pop();
                        keyChunks.push(newName);
                        const targetKeyStart = `${keyChunks.join("/")}/`;

                        const targetKey = sourceKey.replace(new RegExp(`^${prefix}`), targetKeyStart);

                        const _isDir = targetKey[targetKey.length - 1] === "/";
                        const targetChunks = targetKey.split("/");
                        const _newName = _isDir
                            ? targetChunks[targetChunks.length - 2]
                            : targetChunks[targetChunks.length - 1];

                        const renameResult = await renameObject(sourceKey, targetKey, _newName, _isDir);
                        if (!renameResult) {
                            throw new Error(`failed to rename object with key '${sourceKey}'`);
                        }
                    }
                }

                if (listData.CommonPrefixes) {
                    for (const commonPrefix of listData.CommonPrefixes) {
                        await renameObjectsInDirectory(commonPrefix.Prefix || "");
                    }
                }

                return true;
            } catch (error) {
                Toast_error(
                    `Error renaming objects in directory: ${getErrorMessage(error)}. Reload data and repeat same rename action again.`,
                );
                return false;
            }
        }

        async function renameObject(
            sourceKey: string,
            targetKey: string,
            objName: string,
            isDir: boolean,
        ): Promise<boolean> {
            try {
                const copyResult = await s3.send(
                    new CopyObjectCommand({
                        Bucket: bucketName,
                        CopySource: `${bucketName}/${sourceKey}`,
                        Key: targetKey,
                    }),
                );

                let isSameLevelObject = false;
                if (currentFolderId.split("/").length === `${targetKey.replace(/\/+$/, "")}/`.split("/").length - 1) {
                    isSameLevelObject = true;
                }

                if (isSameLevelObject) {
                    setFiles((prevState) => {
                        const newObj: FileData = {
                            id: targetKey,
                            name: objName,
                            isDir,
                        };
                        if (!isDir) {
                            newObj.modDate = copyResult!.CopyObjectResult!.LastModified!;
                        }

                        return [...prevState.filter((pf) => pf.id !== targetKey), newObj];
                    });
                }
            } catch (error) {
                console.error("Error renaming file or directory:", error);
                return false;
            }

            try {
                await s3.send(new DeleteObjectCommand({ Bucket: bucketName, Key: sourceKey }));

                setFiles((prevState) => prevState.filter((f) => f.id !== sourceKey));

                return true;
            } catch (error) {
                console.error("Error renaming file or directory:", error);
                return false;
            }
        }

        if (file.isDir) {
            return renameObjectsInDirectory(file.id);
        } else {
            keyChunks.pop();
            keyChunks.push(newName);
            const targetKey = keyChunks.join("/");
            const sourceKey = file.id;

            return renameObject(sourceKey, targetKey, newName, false);
        }
    }

    async function createFolder(value: string): Promise<boolean> {
        const name = currentFolderId ? `${currentFolderId.trim() + value.trim()}/` : `${value.trim()}/`;

        try {
            const so = await s3.send(new PutObjectCommand({ Bucket: bucketName, Key: name }));
            reloadData();
            return true;
        } catch (err) {
            console.error("Error creating folder", err);
            return false;
        }
    }

    function reloadData() {
        setFiles([]); // empty files' list and show loader
        setChunkCounter(0);
        setContinueMarker(undefined);
        loadMore();
    }

    function updateFullScreenState(newFullScreen: boolean) {
        document.body.classList.toggle("codeEditorFullScreen", newFullScreen);
        setIsFullscreen(newFullScreen);
    }

    function uploadFilesHolder() {
        if (uploadProgressFiles.length === 0) {
            return null;
        }

        const hasClearableUploads = uploadProgressFiles.some(
            (p) => p.status === "in_progress" || p.status === "failed",
        );

        return (
            <div className={C.Files}>
                <div className={cn(C.Heading, isUploadOpen && C.Open)}>
                    <div className="pr-3 flex-grow">
                        <div className="flex justify-between">
                            <div>
                                <b>Uploading files</b>
                            </div>
                            <div>
                                <span>
                                    {uploadStats.uploadedFiles} of {uploadStats.totalFiles} files uploaded
                                </span>{" "}
                                <span>
                                    ({formatBytes(uploadStats.uploadedSize)}/{formatBytes(uploadStats.totalSize)}){" "}
                                    <b>{uploadStats.percentage.toFixed(1)}%</b>
                                </span>
                            </div>
                        </div>

                        <progress className={P.Progress} value={uploadStats.percentage.toFixed(1)} max={100} />
                    </div>

                    <WButton color="primary" action={() => setIsUploadOpen(!isUploadOpen)}>
                        {isUploadOpen ? "Hide Details" : "Show Details"}
                    </WButton>
                </div>

                {isUploadOpen && (
                    <div className={cn(C.Content, isFullscreen && C.Full)}>
                        {uploadProgressFiles
                            .filter((p) => p.status !== "complete" && p.status !== "finalized")
                            .sort((a, b) => (a.orderNr > b.orderNr ? 1 : -1))
                            .map((uf) => (
                                <div key={uf.uploadId} className={C.Item}>
                                    <div className={C.Title}>{uf.key}</div>
                                    <div className="flex-grow">
                                        {uf.errorMessage ? (
                                            <div className="text-error">{uf.errorMessage}</div>
                                        ) : (
                                            <progress
                                                className={P.Progress}
                                                value={((uf.uploadedSize / uf.size) * 100).toFixed(1)}
                                                max={100}
                                            />
                                        )}
                                    </div>
                                    {(uf.status === "in_progress" || uf.status === "failed") && (
                                        <WButton
                                            icon="jp-icon-close"
                                            variant="ghost"
                                            size="xs"
                                            color={uf.status === "in_progress" ? "primary" : "error"}
                                            isDisabled={uf.cancelInProgress}
                                            className="ml-3"
                                            action={() => {
                                                clearUpload(uf);
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                )}

                {isUploadOpen && hasClearableUploads && (
                    <div className={cn(C.Item, C.Cancel, isFullscreen && C.Full)}>
                        <WButton variant="ghost" color="error" action={clearAllUploads}>
                            {uploadProgressFiles.some((p) => p.status === "in_progress")
                                ? "Cancel Uploads"
                                : "Clear Uploads"}
                        </WButton>
                    </div>
                )}
            </div>
        );
    }

    function getFullScreenDivHeight() {
        return isFullscreen
            ? ["paused", "halted", "loading"].includes(browserLoadState)
                ? uploadProgressFiles && !!uploadProgressFiles.length
                    ? isUploadOpen
                        ? "calc(100% - 48px - 62px - 258px)"
                        : "calc(100% - 48px - 62px)"
                    : "calc(100% - 48px)"
                : uploadProgressFiles && !!uploadProgressFiles.length
                  ? isUploadOpen
                      ? "calc(100% - 62px - 258px)"
                      : "calc(100% - 62px)"
                  : "100%"
            : undefined;
    }

    function getObjectsPublicUrl(objectKey: string): string {
        return forcePathStyle
            ? `${endpoint}${bucketName}/${objectKey}`
            : `https://${bucketName}.${endpoint.replace("https://", "")}${objectKey}`;
    }

    if (isInitializing) {
        return <Loading icon="none" />;
    }

    return (
        <div className={isFullscreen ? C.Fullscreen : undefined}>
            {!isFullscreen && uploadFilesHolder()}

            <div className="responsive-table" style={{ height: getFullScreenDivHeight() }}>
                <div className={isFullscreen ? "h-100%" : undefined}>
                    <div className={cn(isFullscreen && "h-100%", C.dragDropArea)} {...getRootProps()}>
                        <input {...getInputProps()} />

                        <div className={C.Buttons}>
                            <WButton
                                variant="ghost"
                                color="muted"
                                icon="jp-icon-refresh"
                                size="xs"
                                action={() => reloadData()}
                            >
                                Reload Data
                            </WButton>

                            <WButton
                                variant="ghost"
                                color="muted"
                                icon={isFullscreen ? "jp-zoom-out-icon" : "jp-zoom-in-icon"}
                                size="xs"
                                action={() => updateFullScreenState(!isFullscreen)}
                            >
                                {isFullscreen ? "Minimize" : "Maximize"}
                            </WButton>
                        </div>

                        <FileBrowser
                            defaultFileViewActionId={ChonkyActions.EnableListView.id}
                            disableDefaultFileActions={true}
                            fileActions={fileActions}
                            files={browserLoadState === "loading" && files.length === 0 ? LOADING_FILES : files}
                            folderChain={folderChain}
                            onFileAction={handleFileAction}
                            enableDragAndDrop={false}
                        >
                            <FileToolbar />
                            <FileSearch />
                            <div className={cn(C.FileList, isFullscreen && C.Full)}>
                                <FileList />
                            </div>
                        </FileBrowser>

                        {isDragActive ? (
                            <div className={C.Empty}>
                                <div>
                                    <MaskIcon className="jp-icon-file-upload size-4rem mb-3" />
                                    <p className="font-size-small">
                                        Drop files here
                                        <br />
                                        Files will be uploaded to "/{bucketName}/{currentFolderId}"
                                    </p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <div
                className={cn(
                    C.LoadMore,
                    isFullscreen && C.Full,
                    ["paused", "halted", "loading"].includes(browserLoadState) && C.State,
                )}
            >
                {browserLoadState === "paused" && (
                    <>
                        <WButton color="primary" className="mr-3" action={loadMore}>
                            Load More
                        </WButton>
                        <p className="text-muted">
                            Your S3 bucket contains a substantial number of files, and we've temporarily halted the
                            loading process. Simply click the "Load More" button to continue loading the next batch of
                            files
                        </p>
                    </>
                )}

                {browserLoadState === "halted" && (
                    <p className="text-muted">
                        We've reached the capacity of our S3 file browser. In order to maintain the stability of our
                        user interface, we've terminated the loading of your bucket contents. Please utilize external
                        tools at your disposal to manage the content within your bucket
                    </p>
                )}

                {browserLoadState === "loading" && (
                    <>
                        <div className="mr-3" style={{ width: 32, height: 32, position: "relative" }}>
                            <Spinner />
                        </div>
                        <p className="text-muted">Loading file list...</p>
                    </>
                )}
            </div>

            {isFullscreen && uploadFilesHolder()}
        </div>
    );
}

export default S3FileBrowser;

function getErrorMessage(error: unknown) {
    const message = error instanceof Error ? error.message : undefined;
    return isObjectWithKey(error, "code")
        ? message
            ? `${message}, code: '${error.code}'`
            : `'${error.code}'`
        : String(error);
}

//#region Mocks

function generateETag(): string {
    // Dummy ETag generator for illustration; replace with actual logic as needed
    return `"${Math.random().toString(36).substring(2, 34)}"`;
}

const mockDate = new Date("2024-06-22T12:34:56Z");

const mockUploadProgress: UploadProgressFile[] = [
    {
        orderNr: 1,
        uploadId: "mock-1",
        key: "half-progress",
        modDate: mockDate,
        size: 1000000,
        uploadedSize: 500000,
        status: "in_progress",
        cancelInProgress: false,
    },
    {
        orderNr: 2,
        uploadId: "mock-2",
        key: "error-state",
        modDate: mockDate,
        size: 5000000,
        uploadedSize: 100000,
        status: "failed",
        errorMessage: "Upload failed: Something went wrong",
        cancelInProgress: false,
    },
];

// Refactor to initialize mockFiles and mockDirectories correctly
let mockFiles: S3Object[] = [
    {
        Key: "file1.txt",
        LastModified: mockDate,
        ETag: generateETag(),
        Size: 100,
        StorageClass: "STANDARD",
    },
    {
        Key: "file2.txt",
        LastModified: mockDate,
        ETag: generateETag(),
        Size: 200,
        StorageClass: "STANDARD",
    },
];

const mockDirectories: CommonPrefix[] = [
    {
        Prefix: "dir1/",
    },
];

class MockS3 {
    constructor(public readonly config: S3ClientConfig) {}

    static make(params: S3ClientConfig) {
        return unsetPropertyCheckProxy(new MockS3(params)) as unknown as S3Client & MockS3;
    }

    getFormattedDate() {
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, "0");
        const day = String(now.getUTCDate()).padStart(2, "0");
        const hours = String(now.getUTCHours()).padStart(2, "0");
        const minutes = String(now.getUTCMinutes()).padStart(2, "0");
        const seconds = String(now.getUTCSeconds()).padStart(2, "0");
        return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
    }

    getAmzDate(date: Date) {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");
        return `${year}${month}${day}`;
    }

    // Usage
    async send(command: unknown): Promise<unknown> {
        switch (true) {
            // Create
            case command instanceof CreateMultipartUploadCommand:
                mockFiles.push({
                    Key: command.input.Key,
                    LastModified: new Date(),
                    Size: 100,
                });
                return Promise.resolve({});

            // Read
            case command instanceof ListObjectsV2Command: {
                const listResp: ListObjectsV2CommandOutput = {
                    $metadata: {},
                    Contents: mockFiles.filter((file) => file.Key?.startsWith(command.input.Prefix!)),
                    CommonPrefixes: mockDirectories.filter((dir) => dir.Prefix?.startsWith(command.input.Prefix!)),
                    Prefix: command.input.Prefix,
                    Delimiter: "/",
                    Name: command.input.Bucket,
                    IsTruncated: false,
                };
                return Promise.resolve(listResp);
            }

            case command instanceof GetObjectAclCommand: {
                const mockUser = "mock-user@example.com";
                const aclResp: GetObjectAclCommandOutput = {
                    $metadata: {},
                    Grants: [
                        {
                            Grantee: {
                                DisplayName: mockUser,
                                ID: mockUser,
                                Type: "CanonicalUser",
                            },
                            Permission: "FULL_CONTROL",
                        },
                    ],
                    Owner: {
                        DisplayName: mockUser,
                        ID: mockUser,
                    },
                };
                return Promise.resolve(aclResp);
            }

            // Update
            case command instanceof PutObjectCommand:
                mockDirectories.push({
                    Prefix: command.input.Key,
                });
                return Promise.resolve({});

            // Delete
            case command instanceof DeleteObjectCommand:
                mockFiles = mockFiles.filter((file) => file.Key !== command.input.Key);
                return Promise.resolve({});

            // Default
            default:
                return notImplemented();
        }
    }

    getSignedUrl(file: FileData, age: number) {
        const now = new Date();
        const amzDate = this.getFormattedDate();
        const amzDateShort = this.getAmzDate(now);

        const search = `?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=A9BP2C6UDTZ5FBNATQOQ%2F${amzDateShort}%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=${amzDate}&X-Amz-Expires=${age}&X-Amz-Signature=603e0995f5957a6d0c728e714b0759c7600d07eafa99d6fc657c7afd94c6b7c1&X-Amz-SignedHeaders=host&x-id=GetObject`;
        // TODO: Use real endpoint from config
        const host = "mock-endpoint.localdomain";
        return new URL(`https://${host}/${file.name}${search}`);
    }

    createMultipartUpload() {
        notImplemented();
    }

    uploadPart() {
        notImplemented();
    }

    completeMultipartUpload() {
        notImplemented();
    }
}

function notImplemented(): never {
    throw new Error("S3 Mock Not implemented");
}

//#endregion

//#region Legacy compatibility stubs

function Analytics_event(event: { category: string; action: string }): void {
    console.debug("Analytics event:", event);
}

const toastOptions: Partial<Toast> = { icon: getResourceIconClass("bucket") };

function Toast_error(message: string): void {
    raiseToast({ ...toastOptions, type: "error", message });
}

function Toast_success(message: string): void {
    raiseToast({ ...toastOptions, type: "success", message });
}

function Toast_info(message: string): void {
    raiseToast({ ...toastOptions, type: "info", message });
}

//#endregion

function DeleteFileOrFolderModal({ files, callback }: { files: FileData[]; callback: () => Promise<boolean> }) {
    const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
    const [confirmed, setConfirmed] = useState<boolean>(files.length === 1 && !files[0].isDir); // additional confirmation by user

    async function handleSubmit() {
        if (!confirmed) {
            return;
        }
        setDeleteInProgress(true);
        await callback();
        setConfirmed(false);
    }

    function handleConfirmationChange(value: boolean) {
        setConfirmed(value);
    }

    const singleFile = singleElement(files);
    const isMultipleFilesOrDir = !singleFile || singleFile.isDir;
    return (
        <WModalContent title="Delete" label="Delete" modalAction={handleSubmit} isActionDisabled={!confirmed}>
            {singleFile ? (
                <ModalRow title={singleFile.isDir ? "Folder" : "File"}>
                    <strong>"{singleFile.name}"</strong> will be deleted permanently.
                </ModalRow>
            ) : (
                <div>
                    Deleting <strong>{files.length}</strong> items.
                </div>
            )}

            {singleFile ? (
                <ModalRow title="Full path">
                    <strong>"/{singleFile.id}"</strong>
                </ModalRow>
            ) : null}
            {isMultipleFilesOrDir ? (
                <div className="pt-3">
                    <WCheckbox isSelected={confirmed} onChange={handleConfirmationChange}>
                        I'm aware that deletion is permanent
                    </WCheckbox>
                </div>
            ) : null}

            {isMultipleFilesOrDir ? (
                <p className="color-muted pt-3 font-size-small">
                    Please note: If you are deleting substantial number of items, the deletion process may result in an
                    inconsistent state, where some items are deleted while others remain. In the event of such
                    occurrences, kindly repeat the the same action once more.
                </p>
            ) : null}
        </WModalContent>
    );
}

interface LinkAge {
    /** seconds */
    value: number;
    title: string;
}

const linkAgeVariants: LinkAge[] = [
    { value: 60 * 60, title: "1 hour" },
    { value: 60 * 60 * 6, title: "6 hours" },
    { value: 60 * 60 * 24, title: "1 day" },
    { value: 60 * 60 * 24 * 3, title: "3 days" },
    { value: 60 * 60 * 24 * 7, title: "7 days" },
];

interface ShareModalProps {
    file: FileData;
    callback: (linkAge: number) => Promise<ShareLinkResult | null>;
}

function ShareModal({ file, callback }: ShareModalProps) {
    //#region Hooks
    const [generatingLinkInProgress, setGeneratingLinkInProgress] = useState<boolean>(false);
    const [linkAge, setLinkAge] = useState<LinkAge>(linkAgeVariants[0]); // default value '1 hour'
    const [generatedLink, setGeneratedLink] = useState<ShareLinkResult | null>(null);

    useEffect(() => {
        void generateShareLink();
    }, []);

    //#endregion

    function generateShareLink(): void {
        setGeneratingLinkInProgress(true);

        setTimeout(async () => {
            setGeneratedLink(await callback(linkAge.value));
            setGeneratingLinkInProgress(false);
        }, 100);
    }

    function handleLinkAgeChange(linkAge: LinkAge): void {
        setLinkAge(linkAge);
        generateShareLink();
    }

    let pathStyleUrl = "";
    let vhostStyleUrl = "";

    if (generatedLink) {
        pathStyleUrl = generatedLink.path_style_url;
        if (pathStyleUrl.length > 40) pathStyleUrl = `${pathStyleUrl.substring(0, 40)}...`;

        if (generatedLink.vhost_style_url) {
            vhostStyleUrl = generatedLink.vhost_style_url;
            if (vhostStyleUrl.length > 40) vhostStyleUrl = `${vhostStyleUrl.substring(0, 40)}...`;
        }
    }

    return (
        <WModalContent title="Temporary Share" modalAction={undefined}>
            <ModalRow title="File">{file.id}</ModalRow>
            <ModalRow title="Sharing duration">
                <RadioGroup
                    className={R.RadioGroup}
                    aria-label="Sharing duration"
                    value={linkAge.title}
                    orientation="horizontal"
                    onChange={(e) => handleLinkAgeChange(linkAgeVariants.find((la) => la.title === e)!)}
                >
                    {linkAgeVariants.map((option) => (
                        <Radio key={option.title} className={RA.Radio} value={option.title}>
                            {option.title}
                        </Radio>
                    ))}
                </RadioGroup>
            </ModalRow>

            {generatedLink && (
                <>
                    <ModalRow title="Path style URL">
                        {!generatingLinkInProgress ? (
                            <ClipBoardTooltip text={generatedLink.path_style_url}>{pathStyleUrl}</ClipBoardTooltip>
                        ) : (
                            <Loading />
                        )}
                    </ModalRow>
                    <ModalRow title="Virtual host style URL">
                        {!generatingLinkInProgress ? (
                            <ClipBoardTooltip text={generatedLink.vhost_style_url}>{vhostStyleUrl}</ClipBoardTooltip>
                        ) : (
                            <Loading />
                        )}
                    </ModalRow>
                </>
            )}

            {generatedLink?.expires ? (
                <p className="color-muted pt-3 font-size-small">
                    The link expires on: <LongDate date={new Date(Date.now() + linkAge.value * 1000)} />
                </p>
            ) : undefined}
        </WModalContent>
    );
}

function objectIsPublic(grants: Grant[] | undefined): boolean {
    if (grants === undefined) {
        return false;
    }
    return grants.some(
        (grant) =>
            grant.Grantee?.Type === "Group" &&
            grant.Grantee?.URI === "http://acs.amazonaws.com/groups/global/AllUsers" &&
            grant.Permission === "READ",
    );
}

function PermissionsModal({
    s3,
    bucketName,
    publicUrl,
    files,
    callback,
}: {
    s3: S3Client;
    bucketName: string;
    publicUrl: string | undefined;
    files: FileData[];
    callback: (permission: "public-read" | "private") => Promise<boolean>;
}) {
    //#region Hooks

    const [isPublic, setIsPublic] = useState<boolean | undefined>(undefined);
    const [objectGrants, setObjectGrants] = useState<Grant[] | undefined>(undefined);
    const [value, setValue] = useState<"private" | "public">("private");

    useEffect(() => {
        discardPromise(getAclData());
        // eslint-disable-next-line react-hooks/exhaustive-deps -- modal props will never change
    }, []);

    //#endregion

    async function getAclData() {
        if (files.length > 1) {
            return;
        }
        try {
            const command = new GetObjectAclCommand({
                Bucket: bucketName,
                Key: files[0].id,
            });
            const data = await s3.send(command);
            setObjectGrants(data.Grants);
            setIsPublic(objectIsPublic(data.Grants));
            setValue(isPublic ? "public" : "private");
        } catch (e) {
            console.error("Error getting ACL data", e);
        }
    }

    async function handleSetPermissions(): Promise<void> {
        if (isPublic === undefined) {
            return;
        }

        await callback(isPublic ? "public-read" : "private");
        await getAclData();
        Toast_success("File(s) permissions saved successfully");
    }

    function handlePrivatePublicChange(v: string) {
        setValue(v as typeof value);
        setIsPublic(!!(v && v === "public"));
    }

    const singleFile = singleElement(files);

    let publicUrlLabel = publicUrl ?? "";
    if (publicUrlLabel.length > 40) {
        publicUrlLabel = `${publicUrlLabel.substring(0, 40)}...`;
    }

    return (
        <WModalContent title="Permissions" label="Save" modalAction={handleSetPermissions}>
            <ModalRow title="File(s)">{singleFile ? singleFile.id : `${files.length} files`}</ModalRow>

            <ModalRow title="Permissions">
                <RadioGroup
                    className={R.RadioGroup}
                    aria-label="Permissions"
                    value={value}
                    orientation="horizontal"
                    onChange={(v) => handlePrivatePublicChange(v as typeof value)}
                >
                    <WTooltip text="Set object's ACL to 'private'">
                        <Radio
                            className={RA.Radio}
                            value="private"
                            isDisabled={singleFile !== undefined && objectGrants === undefined}
                        >
                            Private
                        </Radio>
                    </WTooltip>

                    <WTooltip text="Set object's ACL to 'public-read'">
                        <Radio
                            className={RA.Radio}
                            value="public"
                            isDisabled={singleFile !== undefined && objectGrants === undefined}
                        >
                            Public
                        </Radio>
                    </WTooltip>
                </RadioGroup>
            </ModalRow>

            {!!publicUrl && (
                <ModalRow title="Public URL" ellipsis>
                    {isPublic ? (
                        <ClipBoardTooltip text={publicUrl}>{publicUrlLabel}</ClipBoardTooltip>
                    ) : (
                        <span className="color-muted p-1">{publicUrlLabel}</span>
                    )}
                </ModalRow>
            )}
        </WModalContent>
    );
}

function cleanupName(name: string) {
    let cleanupName = name.replace(/\/\/+/g, "/");
    if (cleanupName.endsWith("/")) {
        cleanupName = cleanupName.slice(0, -1);
    }
    if (cleanupName.startsWith("/")) {
        cleanupName = cleanupName.substring(1);
    }
    return cleanupName;
}

function RenameFileOrFolderModal({ file, callback }: { file: FileData; callback: (name: string) => Promise<boolean> }) {
    const [name, setName] = useState<string>(file.name);
    const [renameInProgress, setRenameInProgress] = useState<boolean>(false);

    async function handleSubmit() {
        setRenameInProgress(true);
        await callback(cleanupName(name));
    }

    const nameFieldTitle = file.isDir ? "New Folder Name" : "New File Name";
    return (
        <WModalContent
            title="Rename"
            label="Rename"
            modalAction={handleSubmit}
            footerNotice={
                file.isDir ? (
                    <p className="jp-message help padding-top-12">
                        Please note: If your folder contains a substantial number of files, the renaming process will
                        take time and may result in an inconsistent state where some files are moved to a new location,
                        while others remain in the old folder. In the event of such occurrences, kindly repeat the
                        action by renaming the same folder once more.
                    </p>
                ) : undefined
            }
        >
            <WTextField
                autoFocus
                isRequired
                label={nameFieldTitle}
                value={name}
                onChange={setName}
                isDisabled={renameInProgress}
            >
                <Input style={{ width: "240px" }} className={cn(FF.FormFieldInput, TF.Input)} />
            </WTextField>
        </WModalContent>
    );
}

function CreateFolderModal({ callback }: { callback: (name: string) => Promise<boolean> }) {
    const [name, setName] = useState<string>("");
    const [createInProgress, setCreateInProgress] = useState<boolean>(false);

    async function handleSubmit() {
        setCreateInProgress(true);
        await callback(cleanupName(name));
        setCreateInProgress(false);
    }

    return (
        <WModalContent title="New Folder" label="Create" modalAction={handleSubmit}>
            <WTextField
                autoFocus
                isRequired
                label="Folder name"
                value={name}
                onChange={setName}
                isDisabled={createInProgress}
            >
                <Input style={{ width: "240px" }} className={cn(FF.FormFieldInput, TF.Input)} />
            </WTextField>
        </WModalContent>
    );
}
